<template>
  <Export
      :date_type="null"
      :date_filter="false"
      :entities="entities"
      :campus_filter="true"
      :campuses="campuses"
      :title="'House Report'"
      :year_filter="true"
      :required_filters="['year']"
  ></Export>
</template>


<script>
import Export from "@/components/exports/Export";
import Campus from "@/models/Campus";


export default {
  name: "sports-houses",
  components: {
    Export,
  },
  data() {
    return {
      entities: "sports-houses",
      admissions_team: [],

    };
  },
  computed: {

    campuses() {
      return Campus.query().with('phases').get()
    }
  },
  mounted() {

    Campus.FetchAll({page: 1, limit: 99})
  },
};
</script>
